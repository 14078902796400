import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { QuestionListState, QuestionListInterface, QuestionByIdListInterface, MilestoneDataInterface } from '../types';
import { set } from 'lodash';
import { ActivityDayStatusEnum } from 'pages/milestoneList/types';

export const initialState: QuestionListState = {
  loading: false,
  editIndex: 0,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  category: "",
  handleChange: 0,
  list: [],
  listAll: [],
  sortColumn: "",
  sortDir: "",
  form: {
    id: "",
    milestoneId: "",
    minAge: 0,
    maxAge: 10,
    isActive: ActivityDayStatusEnum.ACTIVE,
    arabicFooter: "",
    uiid: "",
    hindiFooter: "",
    englishFooter: "",
    englishDescription: "",
    arabicDescription: "",
    hindiDescription: "",
    _id: ""
  },
  queByIdList: [],
  milestone: '',
  MilestoneArray: [],
  milestoneData: {
    milestone: '',
    category: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'questionListState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setMilestoneArray: (state, action: PayloadAction<[]>) => {
      state.MilestoneArray = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setMilestone: (state, action: PayloadAction<string>) => {
      state.milestone = action.payload;
    },
    clearForm: (state) => {
      state.form.minAge = 0;
      state.form.maxAge = 10;
      state.form.id = "";
      state.form.milestoneId = "";
      state.form.isActive = ActivityDayStatusEnum.ACTIVE;
      state.form.arabicFooter = "";
      state.form.hindiFooter = "";
      state.form.englishFooter = "";
      state.form.englishDescription = "";
      state.form.arabicDescription = "";
      state.form.hindiDescription = "";
      state.form._id = "";
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state, actions: PayloadAction<string>) => {},
    doGetDataForEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doDelete: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<QuestionListInterface>>) => {
      state.list = action.payload;
    },

    setHandleChange: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.handleChange = 0;
      } else {
        state.handleChange = state.handleChange + 1;
      }
    },
    doGetListAll: (state) => {},
    doGetMilestoneListFilter: (state) => {},
    setListAll: (state, action: PayloadAction<Array<QuestionListInterface>>) => {
      state.listAll = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<QuestionListInterface>) => {
      state.form = action.payload;
    },

    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doGetMilestoneQuestionById: (state, action: PayloadAction<string>) => {},
    setQueByIdList: (state, action: PayloadAction<Array<QuestionByIdListInterface>>) => {
      state.queByIdList = action.payload;
    },
    doGetMilestoneData: (state) => {},
    setMilestoneData: (state, action: PayloadAction<MilestoneDataInterface>) => {
      state.milestoneData = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

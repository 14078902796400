import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { HelpMeInterface, HelpMeState, IsActiveEnum } from '../types';

export const initialState: HelpMeState = {
  loading: false,
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  formData: {
    id: '',
    _id: '',
    englishQuestion: '',
    hindiQuestion: '',
    arabicQuestion: '',
    englishAnswer: '',
    hindiAnswer: '',
    arabicAnswer: '',
    isActive: IsActiveEnum.ACTIVE
  },
  sortColumn: '',
  sortDir: '',
};

export const useClinicSlice = createSlice({
  name: 'helpMeState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `formData.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.formData.id = '';
      state.formData._id = '';
      state.formData.englishQuestion = '';
      state.formData.hindiQuestion = '';
      state.formData.arabicQuestion = '';
      state.formData.englishAnswer = '';
      state.formData.hindiAnswer = '';
      state.formData.arabicAnswer = '';
      state.formData.isActive = IsActiveEnum.ACTIVE;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<HelpMeInterface>>) => {
      state.List = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<HelpMeInterface>) => {
      state.formData = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { ToysInterface } from '../types';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  doAddToy,
  doGetToysList,
  doGetToyEdit,
  doUpdateToy,
  uplodeDayAcivityImageRequest,
  getDayMealImageRequest
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { selectCategory } from 'pages/milestoneList/redux/selector';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ToysInterface = yield select(selectForm);
  const linkRegex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  if (form.englishTitle.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.categoryId.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select category',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.productLink.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill product link',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (!linkRegex.test(form.productLink)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill valid link',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.minPrice <= 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill min price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxPrice <= 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill max price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (Number(form.maxPrice) <= Number(form.minPrice)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill max price greater than min price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishTitle.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select english title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.shortEnglishDescription.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill Short english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishDescription.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.image.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select an image',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddToy, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    const category: string = yield select(selectCategory);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetToysList,
      `?pageNo=${pageNo}&categoryId=${category}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetToyEdit, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ToysInterface = yield select(selectForm);
  const linkRegex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  if (form.englishTitle.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.categoryId.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select category',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.productLink.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill product link',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (!linkRegex.test(form.productLink)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill valid link',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.minPrice <= 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill min price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxPrice <= 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill max price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (Number(form.maxPrice) <= Number(form.minPrice)) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill max price greater than min price',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.shortEnglishDescription.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill Short english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishDescription.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.image.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select an image',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateToy, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getDayMealImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeDayAcivityImageRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* ToysRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
}

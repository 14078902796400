

export interface MealsCategoryInterface {
  _id: string;
  id: string;
  englishTitle: string; // should be 3
  hindiTitle: string;
  arabicTitle: string;
  isActive: ActivityEnum;
}
export enum ActivityEnum {
  NO = 0,
  YES = 1
}

export interface MealsCategoryState {
  list: MealsCategoryInterface[];
  form: MealsCategoryInterface;
  listAll: MealsCategoryInterface[];
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  
}

export type InitialMealsCategoryState = MealsCategoryState;

import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.userState) {
    return state.userState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.List);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const selectMealList = createSelector([selectDomain], (state) => state.mealList);
export const selectChatList = createSelector([selectDomain], (state) => state.chatList);
export const selectChatForm = createSelector([selectDomain], (state) => state.chatForm);
export const selectUserData = createSelector([selectDomain], (state) => state.userData);
export const selectSendChatData = createSelector([selectDomain], (state) => state.sendChat);
export const selectUserMealPageNo = createSelector([selectDomain], (state) => state.userMealPageNo);
export const selectUserMealPageSize = createSelector([selectDomain], (state) => state.userMealPageSize);
export const selectUserMealTotalRow = createSelector([selectDomain], (state) => state.userMealTotalRow);

export const selectListMessage = createSelector([selectDomain], (state) => state.listMessage);
export const SelectPageNoMessage = createSelector([selectDomain], (state) => state.pageNoMessage);
export const SelectPageSizeMessage = createSelector([selectDomain], (state) => state.pageSizeMessage);
export const SelectTotalRowMessage = createSelector([selectDomain], (state) => state.totalRowMessage);
export const SelectSearchMessage = createSelector([selectDomain], (state) => state.searchMessage);

export const selectListSupport = createSelector([selectDomain], (state) => state.listSupport);
export const SelectPageNoSupport = createSelector([selectDomain], (state) => state.pageNoSupport);
export const SelectPageSizeSupport = createSelector([selectDomain], (state) => state.pageSizeSupport);
export const SelectTotalRowSupport = createSelector([selectDomain], (state) => state.totalRowSupport);
export const SelectLoadingSupport = createSelector([selectDomain], (state) => state.loadingSupport);
export const SelectListSupportDetails = createSelector([selectDomain], (state) => state.listSupportDetails);
export const SelectContactUsDetails = createSelector([selectDomain], (state) => state.contactUsDetails);
export const selectModalLoading = createSelector([selectDomain], (state) => state.contactUsDetailsLoading);

export const selectUserWholeData = createSelector([selectDomain], (state) => state.userWholeData);
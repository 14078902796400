import { FormattedMessage } from 'react-intl';
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  RocketOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { NavItemType } from '../types/menu';
// import { useSelector } from 'react-redux';
// import { RootState } from 'newTypes';
// import { selectStaffPermishion } from 'sections/auth/redux/selector';

const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  RocketOutlined,
  TagsOutlined,
};
const custom: NavItemType = {
  id: 'group-custom-dashboard',
  type: 'group',
  children: [
    {
      id: 'milestone',
      title: <FormattedMessage id="MileStone List" />,
      type: 'collapse',
      icon: icons.AlignLeftOutlined,
      children: [
        {
          id: 'MileStoneCategoryList',
          title: <FormattedMessage id="MileStone Category List" />,
          type: 'item',
          url: '/milestonelist/milestonecategorylist'
        },
        {
          id: 'MileStoneList',
          title: <FormattedMessage id="MileStone List" />,
          type: 'item',
          url: '/mileStonelist/list'
        },
        {
          id: 'QuestionList',
          title: <FormattedMessage id="Question List" />,
          type: 'item',
          url: '/milestonelist/questionlist'
        },
        {
          id: 'activityList',
          title: <FormattedMessage id="Activity List" />,
          type: 'item',
          url: '/daylist/activitylistall'
        }
      ]
    },
    {
      id: 'blog',
      title: <FormattedMessage id="Blog List" />,
      type: 'collapse',
      icon: icons.ProfileOutlined,
      children: [
        {
          id: 'allBlog',
          title: <FormattedMessage id="All Blog" />,
          type: 'item',
          url: '/bloglist/list'
        }
      ]
    },
    {
      id: 'meal',
      title: <FormattedMessage id="Meal" />,
      type: 'collapse',
      icon: icons.PieChartOutlined,
      children: [
        {
          id: 'mealCategory',
          title: <FormattedMessage id="Meals Category List" />,
          type: 'item',
          url: '/meal/mealscategorylist'
        },
        {
          id: 'mealIngredients',
          title: <FormattedMessage id="Meals Ingredient List" />,
          type: 'item',
          url: '/meal/meallistingredient'
        },
        {
          id: 'mealServings',
          title: <FormattedMessage id="Meal Servings" />,
          type: 'item',
          url: '/meal/mealservings'
        },
        {
          id: 'meal',
          title: <FormattedMessage id="Meal List" />,
          type: 'item',
          url: '/meal/meallist'
        },
        {
          id: 'funfood',
          title: <FormattedMessage id="Fun Food List" />,
          type: 'item',
          url: '/meal/funfoodlist'
        }
      ]
    },
    {
      id: 'toys',
      title: <FormattedMessage id="Toys" />,
      type: 'collapse',
      icon: icons.RocketOutlined,
      children: [
        {
          id: 'toylist',
          title: <FormattedMessage id="Toys List" />,
          type: 'item',
          url: '/toys/list'
        },
        {
          id: 'toycategorylist',
          title: <FormattedMessage id="Toys Category" />,
          type: 'item',
          url: '/toys/toyscategorylist'
        }
      ]
    },
    {
      id: 'vaccination',
      title: <FormattedMessage id="Vaccination" />,
      type: 'collapse',
      icon: icons.MedicineBoxOutlined,
      children: [
        {
          id: 'vaccination',
          title: <FormattedMessage id="Vaccination List" />,
          type: 'item',
          url: '/vaccination/list'
        }
      ]
    },
    {
      id: 'plan',
      title: <FormattedMessage id="Plan" />,
      type: 'collapse',
      icon: icons.BookOutlined,
      children: [
        {
          id: 'planlist',
          title: <FormattedMessage id="Plan List" />,
          type: 'item',
          url: '/plan/planlist'
        }
      ]
    },
    {
      id: 'user',
      title: <FormattedMessage id="User" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'user',
          title: <FormattedMessage id="User List" />,
          type: 'item',
          url: '/user/userlist'
        }
      ]
    },
    {
      id: 'chat',
      title: <FormattedMessage id="Messages" />,
      type: 'collapse',
      icon: icons.InfoCircleOutlined,
      children: [
        {
          id: 'chatMessage',
          title: <FormattedMessage id="Messages List" />,
          type: 'item',
          url: '/user/unread'
        }
      ]
    },
    {
      id: 'revenue',
      title: <FormattedMessage id="Revenue" />,
      type: 'item',
      icon: icons.DashboardOutlined,
      url: '/revenue/list'
    },
    {
      id: 'coupon',
      title: <FormattedMessage id="Coupon" />,
      type: 'collapse',
      icon: icons.TagsOutlined,
      children: [
        {
          id: 'coupon',
          title: <FormattedMessage id="Coupon List" />,
          type: 'item',
          url: '/coupon/couponlist'
        }
      ]
    },
    {
      id: 'permission',
      title: <FormattedMessage id="Staffs" />,
      type: 'collapse',
      icon: icons.TeamOutlined,
      children: [
        {
          id: 'staffsaccount',
          title: <FormattedMessage id="Staffs Account" />,
          type: 'item',
          url: '/staffs/staffList'
        }
      ]
    },

    {
      id: 'feedback',
      title: <FormattedMessage id="Feedback" />,
      type: 'collapse',
      icon: icons.MessageOutlined,
      children: [
        {
          id: 'feedback',
          title: <FormattedMessage id="Feedback List" />,
          type: 'item',
          url: '/feedback/list'
        }
      ]
    },
    {
      id: 'help_me',
      title: <FormattedMessage id="Help Me" />,
      type: 'collapse',
      icon: icons.InfoCircleOutlined,
      children: [
        {
          id: 'helpMe',
          title: <FormattedMessage id="Help Me List" />,
          type: 'item',
          url: '/helpMe/list'
        }
      ]
    },
    {
      id: 'contact',
      title: <FormattedMessage id="Contact Us Query" />,
      type: 'item',
      url: '/helpme/support',
      icon: icons.InfoCircleOutlined,
    },
    {
      id: 'setting',
      title: <FormattedMessage id="Tutorial" />,
      type: 'item',
      url: '/controls/tutorial',
      icon: icons.InfoCircleOutlined,
    }
  ]
};

export default custom;


export interface MealsServingInterface {
  _id: string;
  id: string;
  minAge: number;
  maxAge: number;
  noOfServings: number;
  isActive: ActivityEnum;
}
export enum ActivityEnum {
  NO = 0,
  YES = 1
}

export interface MealsServingState {
  list: MealsServingInterface[];
  form: MealsServingInterface;
  listAll: MealsServingInterface[];
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  
}

export type InitialMealsServingState = MealsServingState;

import { PayloadAction } from '@reduxjs/toolkit';
import { ActivityEnum, CouponAvailabilityTypeEnum, CouponInterface, CouponState } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import moment from 'moment';

export const initialState: CouponState = {
  loading: false,
  form: {
    _id: '',
    englishTitle: '',
    hindiTitle: '',
    arabicTitle: '',
    code: '',
    value: 0,
    expiryDate: '',
    isActive: ActivityEnum.ACTIVE,
    availability: CouponAvailabilityTypeEnum.ONE_TIME
  },
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'couponState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.englishTitle = '';
      state.form.hindiTitle = '';
      state.form.arabicTitle = '';
      state.form.code = '';
      state.form.value = 0;
      state.form.expiryDate = '';
      state.form.isActive = ActivityEnum.ACTIVE;
      state.form.availability = CouponAvailabilityTypeEnum.ONE_TIME;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<CouponInterface>>) => {
      state.List = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<CouponInterface>) => {
      state.form._id = action.payload._id;
      state.form.englishTitle = action.payload.englishTitle;
      state.form.hindiTitle = action.payload.hindiTitle;
      state.form.arabicTitle = action.payload.arabicTitle;
      state.form.code = action.payload.code;
      state.form.value = action.payload.value;
      state.form.expiryDate = moment(action.payload.expiryDate).format('YYYY-MM-DD').toString();
      state.form.isActive = action.payload.isActive;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
const Settings = Loadable(lazy(() => import('../pages/Controls/Settings')));
const AppContactUS = Loadable(lazy(() => import('../pages/contact-us')));
const UserProfile = Loadable(lazy(() => import('../pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
//milestone list
const AddCategory = Loadable(lazy(() => import('../pages/milestoneList/categoryList/categoryForm')));
const MilestoneCategoryList = Loadable(lazy(() => import('../pages/milestoneList/categoryList/categoryList')));
const MilestoneList = Loadable(lazy(() => import('../pages/milestoneList/list')));
const AddMilestone = Loadable(lazy(() => import('../pages/milestoneList/form')));
const AddQuestion = Loadable(lazy(() => import('../pages/milestoneList/questionList/questionForm')));
const QuestionList = Loadable(lazy(() => import('../pages/milestoneList/questionList/questionList')));
const ViewQuestionList = Loadable(lazy(() => import('../pages/milestoneList/questionList/viewQuestions')));
//bloglist
const AllBlogsList = Loadable(lazy(() => import('../pages/blogList/list')));
const TrendingBlogsList = Loadable(lazy(() => import('../pages/blogList/trendingBlog/trendingBlogsList')));
const AddBlog = Loadable(lazy(() => import('../pages/blogList/form')));
const AddTrendingBlog = Loadable(lazy(() => import('../pages/blogList/trendingBlog/trendingBlogForm')));
// meal list //MealIngredientList,AddMealsIngredient,MealsCategoryList,AddMealSCategory
const MealList = Loadable(lazy(() => import('../pages/meal/list')));
const Funfoodlist = Loadable(lazy(() => import('../pages/meal/funfoodlist')));
const AddMeal = Loadable(lazy(() => import('../pages/meal/form')));
const MealIngredientList = Loadable(lazy(() => import('../pages/mealsIingredient/list')));
const AddMealsIngredient = Loadable(lazy(() => import('../pages/mealsIingredient/form')));
const MealsCategoryList = Loadable(lazy(() => import('../pages/mealsCategory/list')));
const AddMealSCategory = Loadable(lazy(() => import('../pages/mealsCategory/form')));
const MealServingsList = Loadable(lazy(() => import('../pages/mealServing/list')));
const AddMealServings = Loadable(lazy(() => import('../pages/mealServing/form')));
// toys list //
const ToysList = Loadable(lazy(() => import('../pages/Toys/list')));
const AddToy = Loadable(lazy(() => import('../pages/Toys/form')));
const ToysCategoryList = Loadable(lazy(() => import('../pages/toysCategory/list')));
const AddToysCategory = Loadable(lazy(() => import('../pages/toysCategory/form')));
// Vaccination list //
const VaccinationList = Loadable(lazy(() => import('../pages/vaccination/list')));
const AddVaccination = Loadable(lazy(() => import('../pages/vaccination/form')));
// plan list //
const PlanList = Loadable(lazy(() => import('../pages/planList/list')));
const AddPlan = Loadable(lazy(() => import('../pages/planList/form')));
// user list //
const UserList = Loadable(lazy(() => import('../pages/userList/List')));
const UserForm = Loadable(lazy(() => import('../pages/userList/form')));
const UserMealList = Loadable(lazy(() => import('../pages/userList/mealList')));
// coupon list //
const CouponList = Loadable(lazy(() => import('../pages/coupon/list')));
const AddCoupon = Loadable(lazy(() => import('../pages/coupon/form')));
// staff list //
const StaffsList = Loadable(lazy(() => import('../pages/staffsAccount/list')));
const AddStaff = Loadable(lazy(() => import('../pages/staffsAccount/form')));
//day list
const DayList = Loadable(lazy(() => import('../pages/dayList/list')));
const AddDay = Loadable(lazy(() => import('../pages/dayList/form')));
const ActivityList = Loadable(lazy(() => import('../pages/dayList/activityList/activityList')));
const ActivityListAll = Loadable(lazy(() => import('../pages/dayList/activityList/activityListAll')));
const AddActivity = Loadable(lazy(() => import('../pages/dayList/activityList/activityForm')));
const HelpMeList = Loadable(lazy(() => import('../pages/helpMe/list')));
const AddHelpMe = Loadable(lazy(() => import('../pages/helpMe/form')));
const ConnectUs = Loadable(lazy(() => import('../pages/ContectUs/ContectUs')));
const FeedbackList = Loadable(lazy(() => import('../pages/feedback/list')));
const UserPermissionList = Loadable(lazy(() => import('../pages/permission/userPermission')));
const RevenueList = Loadable(lazy(() => import('../pages/revenue/list')));
const Chat = Loadable(lazy(() => import('../pages/apps/chat'))); //chat
const ChatUnread = Loadable(lazy(() => import('../pages/apps/unreadMessage'))); //chat
const FunFoodForm = Loadable(lazy(() => import('../pages/meal/funFoodForm')));
const SettingsList = Loadable(lazy(() => import('../pages/Settings/list')));
const SettingsForm = Loadable(lazy(() => import('../pages/Settings/form')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'milestonelist',
          children: [
            {
              path: 'milestonecategorylist',
              element: <MilestoneCategoryList />
            },
            {
              path: 'addcategory',
              element: <AddCategory />
            },
            {
              path: 'addcategory/:id',
              element: <AddCategory />
            },
            {
              path: 'list',
              element: <MilestoneList />
            },
            {
              path: 'addmilestone',
              element: <AddMilestone />
            },
            {
              path: 'addmilestone/:id',
              element: <AddMilestone />
            },
            {
              path: 'questionlist',
              element: <QuestionList />
            },
            {
              path: 'viewquestion/:id',
              element: <ViewQuestionList />
            },
            {
              path: 'addquestion',
              element: <AddQuestion />
            },
            {
              path: 'addquestion/:id',
              element: <AddQuestion />
            },
            {
              path: 'addquestion/:id/:qid',
              element: <AddQuestion />
            }
          ]
        },
        {
          path: 'bloglist',
          children: [
            {
              path: 'list',
              element: <AllBlogsList />
            },
            {
              path: 'addblog/:id',
              element: <AddBlog />
            },
            {
              path: 'addblog',
              element: <AddBlog />
            },
            {
              path: 'trendingblogslist',
              element: <TrendingBlogsList />
            },
            {
              path: 'trendingblogslist/:id',
              element: <TrendingBlogsList />
            },
            {
              path: 'addtrendingblog/:id',
              element: <AddTrendingBlog />
            },
            {
              path: 'addtrendingblog',
              element: <AddTrendingBlog />
            }
          ]
        },
        {
          path: 'daylist',
          children: [
            {
              path: 'list',
              element: <DayList />
            },
            {
              path: 'addday/:id',
              element: <AddDay />
            },
            {
              path: 'addday',
              element: <AddDay />
            },
            {
              path: 'activitylist/:id',
              element: <ActivityList />
            },
            {
              path: 'activitylistall',
              element: <ActivityListAll />
            },
            {
              path: 'addactivity/:id',
              element: <AddActivity />
            },
            {
              path: 'addactivity/:id/:qid',
              element: <AddActivity />
            },
            {
              path: 'addactivity',
              element: <AddActivity />
            }
          ]
        },
        {
          path: 'meal',
          children: [
            {
              path: 'meallist',
              element: <MealList />
            },
            {
              path: 'addmeal/:id',
              element: <AddMeal />
            },
            {
              path: 'addmeal',
              element: <AddMeal />
            },
            {
              path: 'funfood',
              element: <FunFoodForm />
            },
            {
              path: 'funfood/:id',
              element: <FunFoodForm />
            },
            {
              path: 'funfoodlist',
              element: <Funfoodlist />
            },
            {
              path: 'meallistingredient',
              element: <MealIngredientList />
            },
            {
              path: 'addmealsingredient/:id',
              element: <AddMealsIngredient />
            },
            {
              path: 'addmealsingredient',
              element: <AddMealsIngredient />
            },
            {
              path: 'mealscategorylist',
              element: <MealsCategoryList />
            },
            {
              path: 'addmealscategory/:id',
              element: <AddMealSCategory />
            },
            {
              path: 'addmealscategory',
              element: <AddMealSCategory />
            },
            {
              path: 'mealservings',
              element: <MealServingsList />
            },
            {
              path: 'addmealservings/:id',
              element: <AddMealServings />
            },
            {
              path: 'addmealservings',
              element: <AddMealServings />
            },
          ]
        },
        {
          path: 'toys',
          children: [
            {
              path: 'list',
              element: <ToysList />
            },
            {
              path: 'addform/:id',
              element: <AddToy />
            },
            {
              path: 'addform',
              element: <AddToy />
            },
            {
              path: 'toyscategorylist',
              element: <ToysCategoryList />
            },
            {
              path: 'addtoyscategory/:id',
              element: <AddToysCategory />
            },
            {
              path: 'addtoyscategory',
              element: <AddToysCategory />
            }
          ]
        },
        {
          path: 'vaccination',
          children: [
            {
              path: 'list',
              element: <VaccinationList />
            },
            {
              path: 'addvaccination/:id',
              element: <AddVaccination />
            },
            {
              path: 'addvaccination',
              element: <AddVaccination />
            }
          ]
        },
        {
          path: 'plan',
          children: [
            {
              path: 'planlist',
              element: <PlanList />
            },
            {
              path: 'addplan/:id',
              element: <AddPlan />
            },
            {
              path: 'addplan',
              element: <AddPlan />
            }
          ]
        },
        {
          path: 'user',
          children: [
            {
              path: 'userlist',
              element: <UserList />
            },
            {
              path: 'userData/:id/',
              element: <UserForm />
            },
            {
              path: 'userData',
              element: <UserForm />
            },
            {
              path: 'meallist',
              element: <UserMealList />
            },
            {
              path: 'meallist/:id/',
              element: <UserMealList />
            },
            {
              path: 'unread',
              element: <ChatUnread />
            },

          ]
        },
        {
          path: 'coupon',
          children: [
            {
              path: 'couponlist',
              element: <CouponList />
            },
            {
              path: 'addcoupon',
              element: <AddCoupon />
            },
            {
              path: 'addcoupon/:id',
              element: <AddCoupon />
            },
          ]
        },
        {
          path: 'staffs',
          children: [
            {
              path: 'staffList',
              element: <StaffsList />
            },
            {
              path: 'addstaff/:id',
              element: <AddStaff />
            },
            {
              path: 'addstaff',
              element: <AddStaff />
            }
          ]
        },
        {
          path: 'revenue',
          children: [
            {
              path: 'list',
              element: <RevenueList />
            }
          ]
        },
        {
          path: 'permission',
          children: [
            {
              path: 'userPermission',
              element: <UserPermissionList />
            }
          ]
        },
        {
          path: 'feedback',
          children: [
            {
              path: 'list',
              element: <FeedbackList />
            }
          ]
        },
        {
          path: 'helpme',
          children: [
            {
              path: 'list',
              element: <HelpMeList />
            },
            {
              path: 'addhelpme/:id',
              element: <AddHelpMe />
            },
            {
              path: 'addhelpme',
              element: <AddHelpMe />
            },
            {
              path: 'support',
              element: <ConnectUs />
            },
          ]
        },
        {
          path: 'controls',
          children: [
            {
              path: 'settings',
              element: <Settings />
            },
            {
              path: 'addtutorial',
              element: <SettingsForm />
            },
            {
              path: 'tutorial',
              element: <SettingsList />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    }
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                }
              ]
            },
            {
              path: 'chat',
              element: <Chat />
            },
            {
              path: 'chat/:id',
              element: <Chat />
            },
            {
              path: 'chat/:id/:mid',
              element: <Chat />
            },
          
            
          ]
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;

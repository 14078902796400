import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { TrendingBlogState, TrendingBlogInterface } from '../types';

export const initialState: TrendingBlogState = {
  loading: false,
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    id: '',
    title: '',
    image: '',
    categoryid: '',
    age: 0,
    description: '',
    credits: 0
  },
  handleChange: 0,
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'trendingBlogState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.title = '';
      state.form.description = '';
      state.form.image = '';
      state.form.credits = 0;
      state.form.categoryid = '';
      state.form.age = 0;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<TrendingBlogInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<TrendingBlogInterface>) => {
      state.form = action.payload;
    },
     setHandleChange: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.handleChange = 0;
      } else {
        state.handleChange = state.handleChange + 1;
      }
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

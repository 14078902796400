// import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
// import { actions } from './slice';
// import { AxiosResponse } from 'axios';
// import { UpdateSchoolPasswordRequest } from 'utils copy/request';
// import { UpdatePasswordInterface } from '../types';
// import { SelectUpdatePasswordFormData } from './selector';
// import { openSnackbar } from 'store/reducers/snackbar';
// import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* UserEditRequest(action: { payload: any }) {
}

// export function* UserPasswordUpdateRequest(action: { payload: { callback: any } }) {
//   yield delay(500);

//   const form: UpdatePasswordInterface = yield select(SelectUpdatePasswordFormData);
//   const data = {

//     password: form.password,
//     confirmPassword: form.newPassword

//   }
//   try {
//     const response: AxiosResponse = yield call(UpdateSchoolPasswordRequest, data);

//     if (response.data.status == false) {
//       yield put(
//         openSnackbar({
//           open: true,
//           message: response.data.message,
//           variant: 'alert',
//           alert: {
//             color: 'error'
//           },
//           close: true
//         })
//       );
//       return;
//     }
//     yield put(actions.clearPasswordForm());
//     yield put(
//       openSnackbar({
//         open: true,
//         message: response.data.message,
//         variant: 'alert',
//         alert: {
//           color: 'success'
//         },
//         close: false
//       })
//     );
//     yield call(action?.payload?.callback());
//   } catch (error: any) {
//     CatchBlockFunction(error);
//   }
// }
export function* authRepoSagaRoleUser() {

  // yield takeLatest(actions.userEdit, UserEditRequest);
  // yield takeLatest(actions.doUpdateUserPassword, UserPasswordUpdateRequest);
}

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { DashboardInterface, DashboardState, ResponseData } from '../types';
import { set } from 'lodash';
export const initialState: DashboardState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  DashboardWallet: 0,
  DashboardFormData: {
    userCount: 0,
    previousUserCount: 0,
    totalIncome: 0,
    previousTotalIncome: 0
  },
  DashboardSearchList: {},
  DashboardGraphDataList: [],
  DashboardList: {
    userCount: 0,
    previousUserCount: 0,
    totalIncome: 0,
    previousTotalIncome: 0
  },
  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  dateFilter: 0,
  startAndEndDate: {
    endDate: "",
    startDate: ""
  },
  chartData: {
    date: [],
    planPurchased: [],
    userData: []
  },
  recentPlanData:[]
};

export const useClinicSlice = createSlice({
  name: 'DashboardState',
  initialState,
  reducers: {
    doGetDashboardList: (state) => {},
    doGetRecentList: (state) => {},
    getDashboardChartData : (state) => {},
    doGetWallet: (state) => {},
    doGetDashboardGraphData: (state, action: PayloadAction<any>) => {},
    setDashboardsList: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardList = action.payload;
    },
    setRecentPlanData: (state, action: PayloadAction<[]>) => {
      state.recentPlanData = action.payload;
    },
    setGraphData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardGraphDataList = action.payload;
    },
    setWalletData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardWallet = action.payload;
    },
    setSearchData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardSearchList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setChartData: (state, action: PayloadAction<ResponseData>) => {
      state.chartData = action.payload;
    },
    setDateFilter: (state, action: PayloadAction<number>) => {
      state.dateFilter = action.payload;
    },
    
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateStartAndEndDateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `startAndEndDate.${action.payload?.key}`, action.payload?.value);
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { PayloadAction } from '@reduxjs/toolkit';
import { ActivityEnum, PlanInterface, PlanState, MilestoneEnum, DefaultEnum, CommunityEnum, BlogEnum } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: PlanState = {
  loading: false,
  formData: {
    id: '',
    androidAmount: '',
    iosAmount: '',
    englishTitle: '',
    hindiTitle: '',
    arabicTitle: '',
    mealFree: '',
    mealPremium: '',
    isDefault: DefaultEnum.NO,
    activityFree: '',
    activityPremium: '',
    community: CommunityEnum.NO,
    milestone: MilestoneEnum.NO,
    toy: MilestoneEnum.NO,
    blogs: BlogEnum.NO,
    tracker: MilestoneEnum.NO,
    ructingType: '',
    mealChat: MilestoneEnum.NO,
    vaccination: MilestoneEnum.NO,
    isActive: ActivityEnum.YES,
    _id: '',
    order: 0
  },
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'planState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `formData.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.formData.id = '';
      state.formData._id = '';
      state.formData.androidAmount = '';
      state.formData.iosAmount = '';
      state.formData.community = CommunityEnum.NO;
      state.formData.milestone = MilestoneEnum.NO;
      state.formData.isDefault = DefaultEnum.NO;
      state.formData.englishTitle = '';
      state.formData.hindiTitle = '';
      state.formData.order = 1;
      state.formData.arabicTitle = '';
      state.formData.tracker = MilestoneEnum.NO;
      state.formData.blogs = BlogEnum.NO;
      state.formData.ructingType = '';
      state.formData.mealChat = MilestoneEnum.NO;
      state.formData.toy = MilestoneEnum.NO;
      state.formData.vaccination = MilestoneEnum.NO;
      state.formData.activityFree = '';
      state.formData.activityPremium = '';
      state.formData.mealFree = '';
      state.formData.mealPremium = '';
      state.formData.isActive = ActivityEnum.YES;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<PlanInterface>>) => {
      state.List = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<PlanInterface>) => {
      state.formData = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import {
  doGetActivityEdit,
  doGetActivitylistAll,
  doGetActivitylist,
  doAddActivity,
  doUpdateActivity,
  getDayAcivityImageRequest,
  uplodeDayAcivityImageRequest,
  doGetCategoryMilestone,
  getDayVideoRequest,
  GetQuestionListFIlterRequest,
  GetMilestoneRequest
} from '../../../../utils copy/request';
import {
  SelectAllPageNo,
  SelectAllPageSize,
  SelectAllSortColumn,
  SelectAllSortDir,
  SelectForm,
  SelectMaxAge,
  SelectMinAge,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir
} from './selector';
import { ActivityListInterface } from '../types';
import { actions } from './slice';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ActivityListInterface = yield select(SelectForm);

  if (form.englishTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishPurpose.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english purpose',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.englishDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  // if (form.englishMaterial.length === 0) {
  //   yield put(
  //     openSnackbar({
  //       open: true,
  //       message: 'please fill english material',
  //       variant: 'alert',
  //       alert: {
  //         color: 'error'
  //       },
  //       close: true
  //     })
  //   );
  //   return;
  // }
  if (form.englishVideo.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english video',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.image.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill image',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.visibleAge === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill visiable age',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxAge == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill max age',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));

  try {
    const response: AxiosResponse = yield call(doAddActivity, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetActivitylist,
      `?questionId=${action.payload}&pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListAllRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectAllPageNo);
    const pageSize: number = yield select(SelectAllPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectAllSortColumn);
    const sortDir: string = yield select(SelectAllSortDir);
    const minAge: number = yield select(SelectMinAge);
    const maxAge: number = yield select(SelectMaxAge);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetActivitylistAll,
      `?&pageNo=${pageNo}&pageSize=${pageSize}&minAge=${minAge}&maxAge=${maxAge}${searchText.length > 0 ? '&search=' + searchText : ''}
     ${sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''}${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setAllTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setListAll(response.data.data.results));
    if (minAge == 0 && maxAge == 0) {
      yield put(actions.setMinAge(response.data.data.activityData.minAge));
      yield put(actions.setMaxAge(response.data.data.activityData.maxAge));
    }
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetActivityEdit, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDisableCheck(true));
    yield put(actions.setFormEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ActivityListInterface = yield select(SelectForm);
  if (form.englishTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishPurpose.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english purpose',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.englishDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishVideo.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english video',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.image.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill image',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.visibleAge === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill visiable age',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.maxAge == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill max age',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateActivity, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

// file upload
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getDayAcivityImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeDayAcivityImageRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetCategoryMilestoneRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetCategoryMilestone, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setCategoryFormData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* VideoUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getDayVideoRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddCatogries(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddEnglishMaterial(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddHindiMaterial(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddArabicMaterial(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetQuestionListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(GetQuestionListFIlterRequest);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setQuestionList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetMilestoneRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    ;
    const response: AxiosResponse = yield call(GetMilestoneRequest);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setMilestoneList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* ActivityListRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetListAll, doGetListAllRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  //  file upload
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.addVideo, VideoUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  // get category milestone and question
  yield takeLatest(actions.doGetCategoryMilestoneById, doGetCategoryMilestoneRequest);
  yield takeLatest(actions.AddCatogries, doAddCatogries);
  yield takeLatest(actions.AddEnglishMaterial, doAddEnglishMaterial);
  yield takeLatest(actions.AddHindiMaterial, doAddHindiMaterial);
  yield takeLatest(actions.AddArabicMaterial, doAddArabicMaterial);
  yield takeLatest(actions.doGetQuestionList, doGetQuestionListRequest);
  yield takeLatest(actions.doGetMilestone, doGetMilestoneRequest);
}

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
// import {   } from '../types';
import { SelectPageNo, SelectPageSize, SelectSearch, selectForm, SelectSortColumn, SelectSortDir, SelectMilestone } from './selector';
import {
  GetQuestionListRequest,
  GetQuestionListAllRequest,
  MileStoneQuestionDeleteRequest,
  AddMileStoneQuestionListRequest,
  GetMileStoneQuestionEditRequest,
  UpdateMileStoneQuestionRequest,
  doGetMilestoneQuestion,
  GetMilestoneListRequest
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { QuestionListInterface } from '../types';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: QuestionListInterface = yield select(selectForm);
  if (form.englishDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.englishFooter.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english footer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.maxAge === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select age range',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(AddMileStoneQuestionListRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetQuestionListRequest,
      `?milestoneId=${action.payload}&pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
    yield put(actions.setMilestoneData(response.data.data.milestoneData[0]));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doDeleteRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(MileStoneQuestionDeleteRequest, action.payload.id);
    if (response && response.data.status == false) {
      return;
    }
    yield call(action?.payload?.callback);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: QuestionListInterface = yield select(selectForm);
  if (form.englishDescription.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english description',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.englishFooter.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english footer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  if (form.maxAge === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please select age range',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdateMileStoneQuestionRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListAllRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    const milestoneId: string = yield select(SelectMilestone);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetQuestionListAllRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}&milestoneId=${milestoneId}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setListAll(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetMileStoneQuestionEditRequest, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetMilestoneQuestionRequest(action: { payload: string }) {
  yield delay(500);
  try {
  
    const response: AxiosResponse = yield call(doGetMilestoneQuestion, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setQueByIdList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetMilestoneListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetMilestoneListRequest );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setMilestoneArray(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* QuestionListRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
  yield takeLatest(actions.doGetListAll, doGetListAllRequest);
  yield takeLatest(actions.doGetDataForEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  //get question by milestone id
  yield takeLatest(actions.doGetMilestoneQuestionById, doGetMilestoneQuestionRequest);
  yield takeLatest(actions.doGetMilestoneListFilter, doGetMilestoneListRequest);
}

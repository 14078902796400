// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from './store/reducers/menu';
import { sliceKey as SnackbarsliceKey, reducer as SnackbarReducer } from './store/reducers/snackbar';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from './sections/auth/redux/slice';
import { authRepoSaga } from './sections/auth/redux/saga';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/redux/slice';
import {  dashboardRepoSagaDashboard } from 'pages/dashboard/redux/saga';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';

import { sliceKey as CategoryListSliceKey, reducer as CategoryListReducer } from 'pages/milestoneList/categoryList/redux/slice';
import { CategoryListRepoSaga } from 'pages/milestoneList/categoryList/redux/saga'; //Categorylist
import { sliceKey as MileStoneListSliceKey, reducer as MileStoneListReducer } from 'pages/milestoneList/redux/slice';
import { MileStoneListRepoSaga } from 'pages/milestoneList/redux/saga'; //milestonelist
import { sliceKey as QuestionListSliceKey, reducer as QuestionListReducer } from 'pages/milestoneList/questionList/redux/slice';
import { QuestionListRepoSaga } from 'pages/milestoneList/questionList/redux/saga'; //QuestionList
import { sliceKey as BlogSliceKey, reducer as BlogReducer } from 'pages/blogList/redux/slice';
import { BlogRepoSaga } from 'pages/blogList/redux/saga'; //bloglist
import { sliceKey as TrendingBlogSliceKey, reducer as TrendingBlogReducer } from 'pages/blogList/trendingBlog/redux/slice';
import { TrendingBlogRepoSaga } from 'pages/blogList/trendingBlog/redux/saga'; //TrendingBlog
import { sliceKey as DayListSliceKey, reducer as DayListReducer } from 'pages/dayList/redux/slice';
import { DayListRepoSaga } from 'pages/dayList/redux/saga'; //daylist
import { sliceKey as ActivityListSliceKey, reducer as ActivityListReducer } from 'pages/dayList/activityList/redux/slice';
import { ActivityListRepoSaga } from 'pages/dayList/activityList/redux/saga'; //ActivityList
import { sliceKey as MealSliceKey, reducer as MealReducer } from 'pages/meal/redux/slice';
import { MealRepoSaga } from 'pages/meal/redux/saga'; //Meal
import { sliceKey as VaccinationSliceKey, reducer as VaccinationReducer } from 'pages/vaccination/redux/slice';
import { VaccinationRepoSaga } from 'pages/vaccination/redux/saga'; //Vaccination
import { sliceKey as PlanSliceKey, reducer as PlanReducer } from 'pages/planList/redux/slice';
import { PlanRepoSaga } from 'pages/planList/redux/saga'; //plan
import { sliceKey as UserSliceKey, reducer as UserReducer } from 'pages/userList/redux/slice';
import { UserRepoSaga } from 'pages/userList/redux/saga'; //user List
import { sliceKey as StaffSliceKey, reducer as StaffReducer } from 'pages/staffsAccount/redux/slice';
import { StaffRepoSaga } from 'pages/staffsAccount/redux/saga'; //staff List
import { sliceKey as RevenueSliceKey, reducer as RevenueReducer } from 'pages/revenue/redux/slice';
import { RevenueRepoSaga } from 'pages/revenue/redux/saga'; //Revenue
import { sliceKey as UserPermissionSliceKey, reducer as UserPermissionReducer } from 'pages/permission/redux/slice';
import { UserPermissionRepoSaga } from 'pages/permission/redux/saga'; //UserPermission
import { sliceKey as FeedbackSliceKey, reducer as FeedbackReducer } from 'pages/feedback/redux/slice';
import { FeedbackRepoSaga } from 'pages/feedback/redux/saga'; //feedback
import { sliceKey as HelpMeSliceKey, reducer as HelpMeReducer } from 'pages/helpMe/redux/slice';
import { HelpMeRepoSaga } from 'pages/helpMe/redux/saga'; //HelpMe
import { sliceKey as ToysSliceKey, reducer as ToysReducer } from 'pages/Toys/redux/slice';
import { ToysRepoSaga } from 'pages/Toys/redux/saga'; //Toys
import { sliceKey as MealCategoryListSliceKey, reducer as MealCategoryListReducer } from 'pages/mealsCategory/redux/slice';
import { MealCategoryListRepoSaga } from 'pages/mealsCategory/redux/saga'; //meal category
import { sliceKey as MealIngredientsSliceKey, reducer as MealIngredientsReducer } from 'pages/mealsIingredient/redux/slice';
import { MealIngredientsListRepoSaga } from 'pages/mealsIingredient/redux/saga'; //meal ingredients
import { sliceKey as ToysCategorySliceKey, reducer as ToysCategoryReducer } from 'pages/toysCategory/redux/slice';
import { ToysCategoryRepoSaga } from 'pages/toysCategory/redux/saga'; //Toys Category
import { sliceKey as MealsServingSliceKey, reducer as MealsServingReducer } from 'pages/mealServing/redux/slice';
import { MealsServingRepoSaga } from 'pages/mealServing/redux/saga'; //meal serving
import { sliceKey as MealsFunFoodSliceKey, reducer as MealsFunFoodReducer } from 'pages/meal/funfood/redux/slice';
import { MealsFunFoodRepoSaga } from 'pages/meal/funfood/redux/saga'; //meal funfood
import { sliceKey as CouponSliceKey, reducer as CouponReducer } from 'pages/coupon/redux/slice';
import { CouponRepoSaga } from 'pages/coupon/redux/saga'; //coupon
import { sliceKey as SettingSliceKey, reducer as SettingReducer } from 'pages/Settings/redux/slice';
import { SettingRepoSaga } from 'pages/Settings/redux/saga';
const App = () => {
  useInjectReducer({ key: SnackbarsliceKey, reducer: SnackbarReducer });
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });

  //******Order List Section******//
  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: dashboardRepoSagaDashboard });

  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser }); //dashboard
  useInjectReducer({ key: CategoryListSliceKey, reducer: CategoryListReducer });
  useInjectSaga({ key: CategoryListSliceKey, saga: CategoryListRepoSaga }); //CategoryList
  useInjectReducer({ key: MileStoneListSliceKey, reducer: MileStoneListReducer });
  useInjectSaga({ key: MileStoneListSliceKey, saga: MileStoneListRepoSaga }); //MileStoneList
  useInjectReducer({ key: QuestionListSliceKey, reducer: QuestionListReducer });
  useInjectSaga({ key: QuestionListSliceKey, saga: QuestionListRepoSaga }); ///questionList
  useInjectReducer({ key: BlogSliceKey, reducer: BlogReducer });
  useInjectSaga({ key: BlogSliceKey, saga: BlogRepoSaga }); //blogList
  useInjectReducer({ key: TrendingBlogSliceKey, reducer: TrendingBlogReducer });
  useInjectSaga({ key: TrendingBlogSliceKey, saga: TrendingBlogRepoSaga }); //TrendingBlog
  useInjectReducer({ key: DayListSliceKey, reducer: DayListReducer });
  useInjectSaga({ key: DayListSliceKey, saga: DayListRepoSaga }); //dayList
  useInjectReducer({ key: ActivityListSliceKey, reducer: ActivityListReducer });
  useInjectSaga({ key: ActivityListSliceKey, saga: ActivityListRepoSaga }); //ActivityList
  useInjectReducer({ key: MealSliceKey, reducer: MealReducer });
  useInjectSaga({ key: MealSliceKey, saga: MealRepoSaga }); //Meal
  useInjectReducer({ key: VaccinationSliceKey, reducer: VaccinationReducer });
  useInjectSaga({ key: VaccinationSliceKey, saga: VaccinationRepoSaga }); //Vaccination
  useInjectReducer({ key: PlanSliceKey, reducer: PlanReducer });
  useInjectSaga({ key: PlanSliceKey, saga: PlanRepoSaga }); //plan
  useInjectReducer({ key: UserSliceKey, reducer: UserReducer });
  useInjectSaga({ key: UserSliceKey, saga: UserRepoSaga }); //user
  useInjectReducer({ key: StaffSliceKey, reducer: StaffReducer });
  useInjectSaga({ key: StaffSliceKey, saga: StaffRepoSaga }); //staffs
  useInjectReducer({ key: RevenueSliceKey, reducer: RevenueReducer });
  useInjectSaga({ key: RevenueSliceKey, saga: RevenueRepoSaga }); //revenue
  useInjectReducer({ key: UserPermissionSliceKey, reducer: UserPermissionReducer });
  useInjectSaga({ key: UserPermissionSliceKey, saga: UserPermissionRepoSaga }); //UserPermission
  useInjectReducer({ key: FeedbackSliceKey, reducer: FeedbackReducer });
  useInjectSaga({ key: FeedbackSliceKey, saga: FeedbackRepoSaga }); //Feedback
  useInjectReducer({ key: HelpMeSliceKey, reducer: HelpMeReducer });
  useInjectSaga({ key: HelpMeSliceKey, saga: HelpMeRepoSaga }); //HelpMe
  useInjectReducer({ key: ToysSliceKey, reducer: ToysReducer });
  useInjectSaga({ key: ToysSliceKey, saga: ToysRepoSaga }); //Toys
  useInjectReducer({ key: MealCategoryListSliceKey, reducer: MealCategoryListReducer });
  useInjectSaga({ key: MealCategoryListSliceKey, saga: MealCategoryListRepoSaga }); //Meals Category
  useInjectReducer({ key: MealIngredientsSliceKey, reducer: MealIngredientsReducer });
  useInjectSaga({ key: MealIngredientsSliceKey, saga: MealIngredientsListRepoSaga }); //Meals Ingredients
  useInjectReducer({ key: ToysCategorySliceKey, reducer: ToysCategoryReducer });
  useInjectSaga({ key: ToysCategorySliceKey, saga: ToysCategoryRepoSaga }); //Toys Category
  useInjectReducer({ key: MealsServingSliceKey, reducer: MealsServingReducer });
  useInjectSaga({ key: MealsServingSliceKey, saga: MealsServingRepoSaga }); //Meals Serving
  useInjectReducer({ key: MealsFunFoodSliceKey, reducer: MealsFunFoodReducer });
  useInjectSaga({ key: MealsFunFoodSliceKey, saga: MealsFunFoodRepoSaga }); //Meals Fun Food
  useInjectReducer({ key: CouponSliceKey, reducer: CouponReducer });
  useInjectSaga({ key: CouponSliceKey, saga: CouponRepoSaga }); //Coupon

  useInjectReducer({ key: SettingSliceKey, reducer: SettingReducer });
  useInjectSaga({ key: SettingSliceKey, saga: SettingRepoSaga }); //Coupon

  useInjectReducer({ key: SettingSliceKey, reducer: SettingReducer });
  useInjectSaga({ key: SettingSliceKey, saga: dashboardRepoSagaDashboard }); //Coupon

  
  
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;

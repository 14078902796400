export interface MealInterface {
  id: string;
  _id: string;
  englishName: string;
  hindiName: string;
  arabicName: string;
  recipeType: MealRecipeEnum;
  image: string;
  type: TypeEnum;
  mealType: string[];
  englishDescription: any;
  hindiDescription: any;
  arabicDescription: any;
  calories: number;
  isActive: MealStatusEnum;
  englishNutrition: string;
  hindiNutrition: string;
  arabicNutrition: string;
  englishVideo: string;
  hindiVideo: string;
  englishVideoStatus: englishVideoStatusInterface[]; // should be 3
  hindiVideoStatus: hindiVideoStatusInterface[];
  arabicVideoStatus: arabicVideoStatusInterface[];
  imageStatus: Imagenterface[];
  arabicVideo: string;
  maxPermit: number;
  minPermit: number;
  mealCategory: string;
  mealIngredients: ingredientsInterface[];
  ingredientsForm:ingredientsInterface;
  minAge: number;
  maxAge: number;
  preparationTime: number;
  cookTime: number;
  youTubeLink: string;
  totalTime: number;
}
export interface englishVideoStatusInterface {
  isDelete: boolean;
  englishVideo: string;
}
export interface ingredientsInterface {
  ingredientId: string;
  englishTitle:string;
  ingredientQuantity: string;
}
export interface hindiVideoStatusInterface {
  isDelete: boolean;
  hindiVideo: string;
}
export interface arabicVideoStatusInterface {
  isDelete: boolean;
  arabicVideo: string;
}
export interface Imagenterface {
  isDelete: boolean;
  image: string;
}
export enum TypeEnum {
  VEG = 0,
  NONVEG = 1,
  EGG = 2
}
export enum MealTypeEnum {
  BREAKFAST = 0,
  LUNCH = 1,
  SNACKS = 2,
  DINNER = 3
}
export enum MealRecipeEnum {
  NORMAL = 1,
  CHEF = 2
}
export enum MealStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0
}
export interface MealState {
  list: MealInterface[];
  form: MealInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialMealState = MealState;

export interface PlanInterface {
  id: string;
  isActive: ActivityEnum;
  _id: string;
  englishTitle: string;
  hindiTitle: string;
  arabicTitle: string;
  mealFree: string;
  mealPremium: string;
  androidAmount: string;
  iosAmount: string;
  order : number;
  isDefault: DefaultEnum;
  activityFree: string;
  activityPremium: string;
  community: CommunityEnum;
  milestone: MilestoneEnum;
  toy: MilestoneEnum;
  blogs: BlogEnum;
  tracker: MilestoneEnum;
  ructingType: string;
  mealChat: MilestoneEnum;
  vaccination: MilestoneEnum;
}
export enum ActivityEnum {
  NO = 0,
  YES = 1
}
export enum PlanRecuringEnum {
  MONTHLY = 0,
  QUATERLY = 1,
  YEARLY = 2
}
export enum BlogEnum {
  NO = 0,
  YES = 1
}
export enum CalendarEnum {
  NO = 0,

  YES = 1
}
export enum CommunityEnum {
  NO = 0,

  YES = 1
}
export enum DefaultEnum {
  NO = 0,
  YES = 1
}
export enum HealthyActivityEnum {
  NO = 0,

  YES = 1
}
export enum MilestoneEnum {
  NO = 0,

  YES = 1
}
export enum ProgressEnum {
  NO = 0,

  YES = 1
}
export interface PlanState {
  List: PlanInterface[];
  formData: PlanInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialPlanState = PlanState;

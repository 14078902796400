export interface MealsIngredientInterface {
  _id: string;
  id: string;
  englishTitle: string; // should be 3
  hindiTitle: string;
  arabicTitle: string;
  isActive: ActivityEnum;
  image:string
  isVisible :ActivityEnum
}
export enum ActivityEnum {
  NO = 0,
  YES = 1
}

export interface MealsIngredientState {
  list: MealsIngredientInterface[];
  form: MealsIngredientInterface;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  listAll: MealsIngredientInterface[];
}export type InitialMealsIngredientState = MealsIngredientState;
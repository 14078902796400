import { PayloadAction } from '@reduxjs/toolkit';
import { LoginMessage } from 'Messages/LoginMessage';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AuthState,  } from '../types';
import { set } from 'lodash';

export const initialState: AuthState = {
  loading: false,
  loginFrom: { username: '', password: '', errors: [], success: '' },
  userData: {
    firstName: '',
    lastName: '',
    email: '',
    usertype: '',
    profilePic: ''
  },
  token: localStorage.getItem('token') || '',
  isLogin: true,
  updatePasswordFormData: { id: '', newPassword: '', password: '' },
  form: []
};

export const useClinicSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUpdateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setStaffPermishion: (state, action: PayloadAction<any>) => {
      state.form = action.payload;
    },
    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setProfileData: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        email: string;
        usertype: string;
        profilePic: string;
      }>
    ) => {
      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.email = action.payload.email;
      state.userData.profilePic = action.payload.profilePic;
      state.userData.usertype = action.payload.usertype;
    },
    getUserProfile: (state) => {},
    getStaffPermission: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doLogin: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.loading = true;
      state.loginFrom.errors = [];
    },
    //Working
    doLogOut: (state) => {
      state.isLogin = true;
    },
    addLoginError: (state, action: PayloadAction<string>) => {
      state.loginFrom.errors.push(action.payload);
    },

    loginSuccess: (state) => {
      state.loginFrom.success = LoginMessage.LOGIN_SUCCESS_MESSAGE;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `loginFrom.${action.payload.key}`, action.payload.value);
      state.loginFrom.errors = [];
    },
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdatePassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.newPassword = '';
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

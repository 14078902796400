import axios from 'axios';
let url = '';
let statucurl = '';
url = process.env.REACT_APP_API||"";
statucurl = process.env.REACT_APP_ASSETSURL||"";
export const path = () => {
  return statucurl;
};
export const urlAPI = () => {
  return url;
};
export default axios.create({
  baseURL: url
});

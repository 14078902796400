import { PayloadAction } from '@reduxjs/toolkit';
import { ToysInterface, ToysState, ToysStatusEnum, ImageInterface, CurrencyEnum, ToyTrendingTypeEnum } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: ToysState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  form: {
    id: '',
    _id: '',
    categoryId: '',
    englishTitle: '',
    hindiTitle: '',
    arabicTitle: '',
    englishDescription: '',
    hindiDescription: '',
    arabicDescription: '',
    minPrice: 0,
    maxPrice: 0,
    currency: CurrencyEnum.INR,
    productLink: '',
    image: '',
    isActive: ToysStatusEnum.ACTIVE,
    imageStatus: [],
    categoryEnglishTitle: '',
    minAge: 0,
    maxAge: 10,
    trending: ToyTrendingTypeEnum.TRAINDING,
    toysCategory: '',
    shortEnglishDescription: '',
    shortHindiDescription: '',
    shortArabicDescription: ''
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'toysState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    setimage: (state, action: PayloadAction<ImageInterface>) => {
      state.form.imageStatus.push(action.payload);
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.categoryId = '';
      state.form.categoryEnglishTitle = '';
      state.form.englishTitle = '';
      state.form.hindiTitle = '';
      state.form.arabicTitle = '';
      state.form.minPrice = 0;
      state.form.maxPrice = 0;
      state.form.englishDescription = '';
      state.form.hindiDescription = '';
      state.form.arabicDescription = '';
      state.form.shortEnglishDescription = '';
      state.form.shortArabicDescription = '';
      state.form.shortHindiDescription = '';
      state.form.image = '';
      state.form.currency = CurrencyEnum.INR;
      state.form.productLink = '';
      state.form.isActive = ToysStatusEnum.ACTIVE;
      state.form.minAge = 0;
      state.form.maxAge = 10;
      state.form.trending = ToyTrendingTypeEnum.TRAINDING;
      state.form.toysCategory = '';
      state.form.imageStatus = [];
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<ToysInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<ToysInterface>) => {
      state.form.id = action.payload.id;
      state.form._id = action.payload._id;
      state.form.categoryId = action.payload.categoryId;
      state.form.minPrice = action.payload.minPrice;
      state.form.maxPrice = action.payload.maxPrice;
      state.form.englishDescription = action.payload.englishDescription;
      state.form.hindiDescription = action.payload.hindiDescription;
      state.form.arabicDescription = action.payload.arabicDescription;
      state.form.shortArabicDescription = action.payload.shortArabicDescription ? action.payload.shortArabicDescription : '';
      state.form.shortHindiDescription = action.payload.shortHindiDescription ? action.payload.shortHindiDescription : '';
      state.form.shortEnglishDescription = action.payload.shortEnglishDescription ? action.payload.shortEnglishDescription : '';
      state.form.image = action.payload.image;
      state.form.currency = action.payload.currency;
      state.form.productLink = action.payload.productLink;
      state.form.isActive = action.payload.isActive;
      state.form.maxAge = action.payload.maxAge;
      state.form.minAge = action.payload.minAge;
      state.form.englishTitle = action.payload.englishTitle;
      state.form.trending = action.payload.trending;
      state.form.toysCategory = action.payload.toysCategory;
      if (action.payload.image) {
        state.form.imageStatus.push({
          image: action.payload.image,
          isDelete: false
        });
      } else {
        state.form.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result: any; callback: any }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

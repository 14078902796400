import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { BlogState, BlogInterface, isActiveEnum, imagenterface } from '../types';

export const initialState: BlogState = {
  loading: false,
  formData: {
    id: '',
    age: 0,
    credits: "",
    englishTitle: '',
    hindiTitle: '',
    arabicTitle: '',
    englishDescription: '',
    hindiDescription: '',
    arabicDescription: '',
    categoryEnglishTitle: '',
    categoryHindiTitle: '',
    categoryArabicTitle: '',
    image: '',
    categoryId: '',
    _id: '',
    isActive: isActiveEnum.active,
    englishShortDescription: '',
    hindiShortDescription: '',
    arabicShortDescription: '',
    imageStatus: [],
    maxAge: 10,
    minAge: 0
  },
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  handleChange: 0,
  sortColumn: '',
  sortDir: '',
  Disabled: false
};

export const useClinicSlice = createSlice({
  name: 'blogState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.formData.imageStatus[i].isDelete = true;
      }
    },
    setimage: (state, action: PayloadAction<imagenterface>) => {
      state.formData.imageStatus.push(action.payload);
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `formData.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.formData.englishTitle = '';
      state.formData.hindiTitle = '';
      state.formData.arabicTitle = '';
      state.formData.englishDescription = '';
      state.formData.hindiDescription = '';
      state.formData.arabicDescription = '';
      state.formData.englishShortDescription = '';
      state.formData.hindiShortDescription = '';
      state.formData.arabicShortDescription = '';
      state.formData.credits = "";
      state.formData.categoryId = '';
      state.formData.categoryHindiTitle = '';
      state.formData.categoryArabicTitle = '';
      state.formData.image = '';
      state.formData.minAge = 0;
      state.formData.maxAge = 10;
      state.formData.id = '';
      state.formData._id = '';
      state.formData.imageStatus = [];
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => { },
    doGetList: (state) => { },
    doGetEdit: (state, action: PayloadAction<string>) => { },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => { },
    setList: (state, action: PayloadAction<Array<BlogInterface>>) => {
      state.List = action.payload;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.Disabled = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<BlogInterface>) => {
      state.formData._id = action.payload._id;
      state.formData.arabicDescription = action.payload.arabicDescription;
      state.formData.arabicTitle = action.payload.arabicTitle;
      state.formData.categoryEnglishTitle = action.payload.categoryEnglishTitle;
      state.formData.categoryId = action.payload.categoryId;
      state.formData.englishDescription = action.payload.englishDescription;
      state.formData.image = action.payload.image;
      state.formData.englishTitle = action.payload.englishTitle;
      state.formData.hindiDescription = action.payload.hindiDescription;
      state.formData.hindiTitle = action.payload.hindiTitle;
      state.formData.isActive = action.payload.isActive;
      state.formData.arabicShortDescription = action.payload.arabicShortDescription;
      state.formData.categoryHindiTitle = action.payload.categoryHindiTitle;
      state.formData.categoryArabicTitle = action.payload.categoryArabicTitle;
      state.formData.maxAge = action.payload.maxAge;
      state.formData.minAge = action.payload.minAge;
      state.formData.credits = action.payload.credits;
      state.formData.englishShortDescription = action.payload.englishShortDescription;
      state.formData.hindiShortDescription = action.payload.hindiShortDescription;
      if (action.payload.image) {

        state.formData.imageStatus.push({
          image: action.payload.image,
          isDelete: false
        })
      } else {
        state.formData.imageStatus.push({
          image: "",
          isDelete: false
        });
      }
    },
    setHandleChange: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.handleChange = 0;
      } else {
        state.handleChange = state.handleChange + 1;
      }
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => { },
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result : any;callback: any }>) => { },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

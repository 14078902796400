import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.activityState) {
    return state.activityState;
  } else {
    return initialState;
  }
};
export const SelectList = createSelector([selectDomain], (state) => state.list);
export const SelectListAll = createSelector([selectDomain], (state) => state.listAll);
export const SelectForm = createSelector([selectDomain], (state) => state.form);
export const SelectEditindex = createSelector([selectDomain], (state) => state.editIndex);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectHandleChange = createSelector([selectDomain], (state) => state.handleChange);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.disableCheck);
export const SelectSearchCategory = createSelector([selectDomain], (state) => state.category);
export const SelectSearchMileStone = createSelector([selectDomain], (state) => state.milestone);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const SelectCategoryMilestoneFormData = createSelector([selectDomain], (state) => state.CategoryMilestoneForm);
export const SelectAllPageNo = createSelector([selectDomain], (state) => state.allPageNo);
export const SelectAllPageSize = createSelector([selectDomain], (state) => state.allPageSize);
export const SelectAllTotalRow = createSelector([selectDomain], (state) => state.allTotalRow);
export const SelectAllSortDir = createSelector([selectDomain], (state) => state.allSortDir);
export const SelectAllSortColumn = createSelector([selectDomain], (state) => state.allSortColumn);
export const SelectSelectedCategories = createSelector([selectDomain], (state) => state.form.selectedCategories);
export const SelectSelectedCategoriesForm = createSelector([selectDomain], (state) => state.form.selectedCategoriesForm);
export const SelectQuestion = createSelector([selectDomain], (state) => state.question);
export const SelectQuestionList = createSelector([selectDomain], (state) => state.questionList);
export const SelectMilestoneList = createSelector([selectDomain], (state) => state.milestoneList);
export const SelectMinAge = createSelector([selectDomain], (state) => state.minAge);
export const SelectMaxAge = createSelector([selectDomain], (state) => state.maxAge);